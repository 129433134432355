@import "../../../core/consts/styleConsts.scss";

.infoTitle {
    @include MAIN_TXT($SUB_TEXT);
    font-weight: 600;
    border-bottom: 1px solid #ECECEC;
    padding: 24px 0;
    margin: 0;
}

.fieldWrap {
    width: 100%;
    display: flex;
    border-bottom: 1px solid #ECECEC;
    padding: 24px 0;
    @media screen and (max-width: 599px) {
        flex-direction: column;
        gap: 8px;
      }
}

.fieldItem {
    @include MAIN_TXT($SUB_TEXT);
    width: 50%;
    @media screen and (max-width: 599px) {
        width: 100%;
      }
}

.fieldItemValue {
    @include MAIN_TXT($MAIN_TEXT);
    width: 50%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    overflow: auto;
    @media screen and (max-width: 599px) {
        width: 100%;
      }
}

.buttonHover {
    cursor: pointer;
    fill: #CCCCCC;
}

.buttonHover:hover {
    fill: #F4D35E;
}