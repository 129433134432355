.firstBlock {
    padding-top: 24px;
}

.blocks {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 52px;
}

.block {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.button {
    width: 100%;
}

.buttonContent {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 7px;
    padding: 22px 50px;
    color: rgba(255, 255, 255, 1);
    .text {
        font-family: "Inter";
        line-height: 20px;
        font-size: 16px;
        font-weight: 600;
    }
}

.infoText {
    text-align: center;
}

.textLink {
    cursor: pointer;
    color: #6ad5cb;
}

.subtitle {
    font-family: "Inter";
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    color: #636363;
}
