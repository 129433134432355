// @import url("../src/core/fonts/GothamPro/stylesheet.css");
// @import url("../src/core/fonts/Montserrat/stylesheet.css");
@import "./core/consts/styleConsts.scss";
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;600;900&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;900&family=Montserrat:wght@300;400;500;600;700;900&family=Poppins:wght@300;400;500;600;700&display=swap');

// @font-face {
//   font-family: "Inter";
//   src: url("./assets/fonts/Inter-Light.ttf");
//   font-weight: 300;
//   font-style: normal;
// }

// @font-face {
//   font-family: "Inter";
//   src: url("./assets/fonts/Inter-Regular.ttf");
//   font-weight: 400;
//   font-style: normal;
// }

// @font-face {
//   font-family: "Inter";
//   src: url("./assets/fonts/Inter-Medium.ttf");
//   font-weight: 500;
//   font-style: normal;
// }

// @font-face {
//   font-family: "Inter";
//   src: url("./assets/fonts/Inter-SemiBold.ttf");
//   font-weight: 600;
//   font-style: normal;
// }

// @font-face {
//   font-family: "Inter";
//   src: url("./assets/fonts/Inter-Bold.ttf");
//   font-weight: 700;
//   font-style: normal;
// }

// @font-face {
//   font-family: "Inter";
//   src: url("./assets/fonts/Inter-ExtraBold.ttf");
//   font-weight: 800;
//   font-style: normal;
// }

html,
body {
  font-family: "Inter", sans-serif;
  // font-size: 12pt;
  @include MAIN_TXT($MAIN_TEXT);
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
}

* {
  box-sizing: border-box;
}

p {
  padding: 0;
  margin: 0;
}

a:hover {
  color: #204a66;
  // transform: scale(1.001);
  transition: all ease-in-out 0.3s;
}

@media (max-width: 1000px) {
  html,
  body {
    font-size: 8pt;
  }
}

// @media (max-width: 480px) {

//   html,
//   body {
//     font-size: 7pt;
//   }
// }

.page_title {
  @include H1($MAIN_TEXT);
  @media screen and (max-width: 760px) {
    font-size: 24px;
  }
}

// *****************************Main UI Components*************************
%main_input {
  min-width: 280px;
  min-height: 64px;
  border-radius: 12px;
  border: 1px solid #cccccc;

  &:hover {
    border: 1px solid #636363;
  }

  &:focus {
    border: 1px solid #242424;
  }

  &:disabled {
    border: 1px solid #242424;
    background: linear-gradient(0deg, #cccccc, #cccccc),
      linear-gradient(0deg, #ececec, #ececec);
  }
}

.main_input {
  @extend %main_input;
}

.main_input_error {
  @extend %main_input;
}

%main_button {
  min-height: 64px;
  min-width: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border-radius: 12px;
  cursor: pointer;
  transition: all 0.5s;
  border: none;

  @include SMBOLD_TXT($SUB_TEXT);
  &:hover {
    background: $SUB_TEXT;
  }
  &:disabled {
    cursor: not-allowed;
    background: $BORDER;
    color: $SUB_TEXT;
  }
}
%main_button_outlined {
  @extend %main_button;
  @include SMBOLD_TXT($SUB_TEXT);
  &:hover {
    border: 1px solid $MAIN_TEXT;
    background: $DIVIDER;
  }
  &:disabled {
    cursor: not-allowed;
    border: 1px solid $BORDER;
    background: $DIVIDER;
    color: $BORDER;
  }
}
%main_button_white {
  @extend %main_button;
  @include SMBOLD_TXT($SUB_TEXT);
  &:hover {
    // border: 1px solid $MAIN_TEXT;
    background: $DIVIDER;
  }
  &:disabled {
    cursor: not-allowed;
    // border: 1px solid $BORDER;
    background: $DIVIDER;
    color: $BORDER;
  }
}

.button_outlined {
  @extend %main_button_outlined;
  border: 1px solid $SUB_TEXT;
  background: $BACKGROUND;
}

.button_black {
  @extend %main_button;
  background: $MAIN_TEXT;

  color: $BACKGROUND;
}
.button_white {
  @extend %main_button_white;
  background-color: $BACKGROUND;
  border: none;
}
.button_outlined_s {
  @extend %main_button_outlined;
  border: 1px solid $SUB_TEXT;
  background: $BACKGROUND;
  min-height: 48px;
}

.button_black_s {
  @extend %main_button;
  background: $MAIN_TEXT;
  color: $BACKGROUND;
  min-height: 48px;
}
.button_white_s {
  @extend %main_button_white;
  background-color: $BACKGROUND;
  border: none;
  min-height: 48px;
}

%count_round {
  @include SMALL_TXT($MAIN_TEXT);
  width: 20px;
  min-width: 20px;
  height: 20px;
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.redRound {
  @extend %count_round;
  background: $ERROR;
}

.yellowRound {
  @extend %count_round;
  background: $ATTENTION;
}
.grayRound {
  @extend %count_round;
  background: $DIVIDER;
  min-width: 20px;
  width: auto;
}

%small_round {
  width: 12px;
  min-width: 12px;
  height: 12px;
  border-radius: 50%;
  overflow: hidden;
}

.redRoundSmall {
  @extend %small_round;
  background: $ERROR;
}

.yellowRoundSmall {
  @extend %small_round;
  background: $ATTENTION;
}

.divider {
  background: #ececec;
  height: 1px;
  width: 100%;
}

.attention_text {
  color: $ATTENTION;
  font-weight: 400;
}
.accept_button {
  @include SMBOLD_TXT($ACCEPT);
  font-weight: 400;
  background-color: white;
  border: none;
  cursor: pointer;
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}
.spacer {
  flex-grow: 1;
}
.warning {
  color: $ERROR;
}
.link {
  @include SMBOLD_TXT($ACCEPT);
  cursor: pointer;
}

// ********************table**********************
.table {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 24px;
  @media screen and (max-width: 1000px) {
    gap: 8px;
  }
}
.table_row {
  width: 100%;
  display: flex;
  padding: 24px;
  gap: 24px;
  align-items: start;

  border-radius: 16px;
  border: 1px solid var(--border, #ccc);

  @include SMALL_TXT($SUB_TEXT);

  @media screen and (max-width: 1000px) {
    flex-direction: column;
  }
}

.table_text {
  @include MAIN_TXT($MAIN_TEXT);
}

%table_item {
  min-height: 100%;
  display: flex;
  align-items: start;
  justify-content: center;
  flex-direction: column;
  gap: 5px;
}

.table_item_5 {
  @extend %table_item;
  width: 5%;

  @media screen and (max-width: 1000px) {
    width: 100%;
  }
}

.table_item_7_5 {
  @extend %table_item;
  width: 7.5%;

  @media screen and (max-width: 1000px) {
    width: 100%;
  }
}

.table_item_10 {
  @extend %table_item;
  width: 10%;

  @media screen and (max-width: 1000px) {
    width: 100%;
  }
}

.table_item_12_5 {
  @extend %table_item;
  width: 12.5%;

  @media screen and (max-width: 1000px) {
    width: 100%;
  }
}

.table_item_15 {
  @extend %table_item;
  width: 15%;

  @media screen and (max-width: 1000px) {
    width: 100%;
  }
}

.table_item_20 {
  @extend %table_item;
  width: 20%;

  @media screen and (max-width: 1000px) {
    width: 100%;
  }
}

.table_item_30 {
  @extend %table_item;
  width: 30%;

  @media screen and (max-width: 1000px) {
    width: 100%;
  }
}

.table_item_40 {
  @extend %table_item;
  width: 40%;

  @media screen and (max-width: 1000px) {
    width: 100%;
  }
}

// ***************************MUI****************
// .MuiFilledInput-underline{
//   border-color: red;
// }

// **************************MUI-TAB*******************************
.tab_main {
  display: flex;
  align-items: center;
  gap: 8px;

  border-radius: 12px;
  border: 1px solid $BORDER;
  padding: 12px 24px;
  background-color: $BACKGROUND;
  @include MAIN_TXT($MAIN_TEXT);
  transition: all 0.3s;
  color: $SUB_TEXT;

  @media screen and (max-width: 480px) {
    padding: 12px 4px;
    gap: 4px;
  }
  &:hover {
    border: 1px solid $ACCEPT;
  }
  &:active {
    border: 1px solid $MAIN_TEXT;
  }
  &:disabled {
    border: 1px solid $DIVIDER;
  }

  .MuiButtonBase-root {
    border: none !important;
  }
}

.Mui-selected {
  .tab_main {
    border: 1px solid $MAIN_TEXT;
    @include MAIN_TXT($MAIN_TEXT);
    &:hover {
      border: 1px solid $ACCEPT;
    }
    &:active {
      border: 1px solid $MAIN_TEXT;
    }
    &:disabled {
      border: 1px solid $DIVIDER;
    }
  }
}
