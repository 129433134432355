$MAIN_TEXT: #242424;
$SUB_TEXT: #636363;
$BORDER: #cccccc;
$DIVIDER: #ececec;
$BACKGROUND: #ffffff;
$BRAND: #99d600;
$ATTENTION: #f4d35e;
$ERROR: #e24e1b;
$ACCEPT: #6ad5cb;

$mobile: 480px;
$tablet: 760px;

%main {
  color: $BRAND;
}

@mixin SMALL_TXT($color) {
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: $color;
}

@mixin MAIN_TXT($color) {
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: $color;
}

@mixin SMBOLD_TXT($color) {
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  color: $color;
}

@mixin H1($color) {
  color: $color;
  font-family: Inter;
  font-size: 48px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
}

@mixin H2($color) {
  color: $color;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
}
