.box {
  display: flex;
  align-items: start;
}
.buttons {
  display: flex;
  gap: 16px;
  align-items: center;
  width: 100%;
  margin-top: 32px;
}
