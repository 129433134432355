@import "../../../core/consts/styleConsts.scss";

.container {
  background: #ffffff;
  box-shadow: 0px 4px 64px 0px #6ad5cb3d;
  border-radius: 24px;
  width: 100%;
  padding: 80px 40px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  background-image: url("../../../core/consts/images/logoGreen.png"),
    url("../../../core/consts/images/logoGreen2XS.png");
  background-size: auto;
  background-position: right top, left bottom;
  background-repeat: no-repeat;
  min-width: 330px;
  @media screen and (max-width: 760px) {
    padding: 80px 16px;
  }
  @media screen and (max-width: 480px) {
    padding: 40px 16px;
    background-image: none;
    border-radius: 0;
    box-shadow: none;
  }
}

.subTitle {
  color: var(--subtext, #636363);
  color: COLORS;
  font-size: 16px;
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.leads_box {
  // margin-top: 24px;
}

.filter_container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.filter_box {
  width: 100%;
  display: flex;
  // flex-wrap: wrap;
  gap: 16px;

  @media screen and (max-width: 760px) {
    flex-direction: column;
  }
}

.filter_item {
  width: 50%;

  @media (max-width: 780px) {
    width: 100%;
  }
}

.new_flag {
  border-radius: 16px;
  background: $BRAND;
  display: flex;
  height: 20px;
  padding: 0px 12px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @include SMALL_TXT(#242424);
}

.notes {
  @include SMALL_TXT($MAIN_TEXT);
  display: flex;
  gap: 5px;
  align-items: center;
}

.redRound {
  width: 8px;
  height: 8px;
  background: #e24e1b;
  border-radius: 50%;
}

.yellowRound {
  width: 8px;
  height: 8px;
  background: #f4d35e;
  border-radius: 50%;
}

.totalcount_sort {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  align-items: center;
  gap: 12px;
  @include MAIN_TXT($MAIN_TEXT);
}

.blue_select {
  @include SMBOLD_TXT($ACCEPT);
  min-width: 300px;
  text-align: right;
  border-color: transparent;
  font-weight: 600 !important;
  height: 30px;
  border: 1px solid transparent !important;

  svg {
    color: #6ad5cb;
  }
}

.tab_flex {
  display: flex;
  align-items: center;
  gap: 8px;

  border-radius: 12px;
  border: 1px solid $BORDER;
  padding: 12px 24px;
  background-color: $BACKGROUND;
  @include MAIN_TXT($MAIN_TEXT);
  &:hover {
    border: 1px solid $ACCEPT;
  }
  &:active {
    border: 1px solid $MAIN_TEXT;
  }
  &:disabled {
    border: 1px solid $DIVIDER;
  }
}
.tabs {
  // font-family: Inter;
  // font-size: 16px;
  // font-weight: 400;
  // line-height: 19px;
  // letter-spacing: 0em;
  // text-align: left;
  // text-transform: none;

  // max-width: 100%;
  width: 100%;
  // overflow-x: auto;
  // background-color: #6ad5cb;
  // -webkit-scrollbar: {
  //   width: 0.2em;
  //   height: 0.2em;
  // }
  // @media screen and (max-width: $mobile) {
  //   overflow-x: scroll;
  // }
}
.lead_item {
  cursor: pointer;
  background-color: $BACKGROUND;
  &:hover {
    background-color: $DIVIDER;
  }
}

.no_leads {
  width: 100%;
  padding: 120px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;
  @include MAIN_TXT($MAIN_TEXT);
}
