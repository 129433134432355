@import "../../../core/consts/styleConsts.scss";

.container {
  background: #ffffff;
  box-shadow: 0px 4px 64px 0px #6ad5cb3d;
  border-radius: 24px;
  width: 100%;
  padding: 80px 40px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  background-image: url("../../../core/consts/images/logoGreen.png"),
    url("../../../core/consts/images/logoGreen2XS.png");
  background-size: auto;
  background-position: right top, left bottom;
  background-repeat: no-repeat;
  @include MAIN_TXT($SUB_TEXT);
  @media screen and (max-width: 760px) {
    padding: 80px 16px;
  }
  @media screen and (max-width: 480px) {
    padding: 40px 16px;
    background-image: none;
    border-radius: 0;
    box-shadow: none;
  }
}
.sub_titile {
  @include MAIN_TXT($MAIN_TEXT);
}

.note_item_box {
  border-radius: 16px;
  border: 1px solid var(--border, #ccc);
  background: var(--background, #fff);
  min-height: 200px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 32px 24px 24px 24px;
}
.date {
  @include SMALL_TXT($BORDER);
}
.flex {
  display: flex;
  gap: 4px;
  align-items: center;
}
.note_title {
  @include SMBOLD_TXT($MAIN_TEXT);
}
.butB {
  padding: 16px 24px;
}
