.dialog {
    max-width: 100%;
    @media screen and (max-width: 780px) {
        width: 100%;
    }
}

.paper {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 40px;
}

.header {
    display: flex;
    gap: 20px;
    justify-content: space-between;
    align-items: center;
}
