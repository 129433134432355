@import "../../core/consts/styleConsts.scss";

.outer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: start;
}

.mobile_next {
    width: 100%;
    margin-bottom: 5px;
    min-width: 300px;
}

.container {
    display: flex;
    align-items: flex-end;
    gap: 6px;
    justify-content: flex-start;
    margin-left: -6px;
    @include SMBOLD_TXT($SUB_TEXT);
}

%page_item {
    display: flex;
    min-width: 48px;
    height: 48px;
    padding: 0px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 12px;
    border: 1px solid $SUB_TEXT;
    background: $BACKGROUND;
    cursor: pointer;
    @include SMBOLD_TXT($SUB_TEXT);
    &:disabled{
        opacity: 0.4;
        cursor: not-allowed
    }
}

.page_item {
    @extend %page_item;
}

.page_item_current {
    @extend %page_item;
    border-radius: 12px;
    background: $MAIN_TEXT;
    color: white;
}