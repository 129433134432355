.input {
  display: none;
}

.label {
  display: flex;
  align-items: center;
  gap: 6px;

  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.15px;
  /* color: rgba(0, 0, 0, 0.75); */

  cursor: pointer;
}

.icon {
  min-width: 10px;
  min-height: 10px;
}
