@import "../../core/consts/styleConsts.scss";

.title_box {
    display: flex;
    width: 100%;
    justify-content: space-between;
    @include H2($MAIN_TEXT);
    margin-bottom: 10px;
}

.children{
    @include MAIN_TXT($SUB_TEXT);
}

.button_box {
    display: flex;
    margin-top: 16px;
    gap: 16px;
}