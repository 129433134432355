@import "../../core/consts/styleConsts.scss";
.layout_main {
    width: 100%;
    min-height: 100vh;
    background: $ACCEPT;

    display: flex;
    flex-direction: column;

    // background-image: url("../../core/consts/images/logoWhite.png");
    // background-size: auto;
    // background-position: 93% 200px;
    // background-repeat: no-repeat;
    position: relative;
    z-index: 1;
    overflow: hidden;
    @media screen and (max-width: $mobile) {
        background-color: $BACKGROUND;
    }
}
.layout_main:before {
    z-index: -1;
    position: absolute;
    right: 7%;
    top: 200px;
    content: url("../../core/consts/images/logoWhite.png");
    opacity: 0.4;
}

.container {
    width: 100%;
    display: flex;
    padding: 50px 16%;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;

    min-height: 100%;

    @media screen and (max-width: $tablet) {
        padding: 10px 20px;
    }
    @media screen and (max-width: $mobile) {
        padding: 0;
    }
}

.spacer {
    flex: 1;
}

.header {
    width: 100%;
    min-height: 80px;
    padding: 25px 16%;
    background: $BACKGROUND;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: Inter;
    font-size: 24px;
    font-weight: 400;
    line-height: 29px;
    letter-spacing: 0em;

    @media screen and (max-width: 760px) {
        padding: 10px 20px;
    }
}

.menuDesktop {
    @include MAIN_TXT($SUB_TEXT);
    display: flex;
    // overflow: auto;
    gap: 24px;
    text-decoration: none;
    @media screen and (max-width: 600px) {
        display: none;
    }
}

.menuMobileContainer {
    display: flex;
    flex-direction: column;
    padding: 16px 24px;
    width: 100vw;
}

.menuMobileHeader {
    display: flex;
    justify-content: space-between;
    margin: 0 0 32px;
}

.menuMobile {
    @include MAIN_TXT($SUB_TEXT);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    overflow: auto;
    gap: 32px;
    text-decoration: none;
}

.menu_item {
    @include MAIN_TXT($SUB_TEXT);
    text-decoration: none;
    cursor: pointer;
}
.menu_item_active {
    @include MAIN_TXT($MAIN_TEXT);
    text-decoration: none;
}

.containerBlock {
    // width: 100%;
    // margin: 0 auto;
    // padding: 25px 16%;

    // @media screen and (max-width: 1022px) {
    //     width: 100%;
    //     padding: 0 16px;
    // }

    // @media screen and (max-width: 760px) {
    //     padding: 10px 20px;
    // }
}

.logo {
    width: 126px;
    height: 35px;
}

.footer {
    width: 100%;
    padding: 30px 16% 80px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 8px;
    color: $BACKGROUND;

    @media screen and (max-width: 760px) {
        padding: 10px 20px;
    }
    @media screen and (max-width: 480px) {
        background-color: $ACCEPT;
    }

    .text {
        color: rgba(255, 255, 255, 1);
        font-family: "Inter";
        font-size: 16px;
        font-weight: 400;
        line-height: 19px;
    }
}

.textWrapper {
    display: flex;
    gap: 80px;

    @media screen and (max-width: 1023px) {
        flex-direction: column;
        gap: 40px;
    }
}

.left {
    width: 390px;
    min-width: 390px;
    display: flex;
    flex-direction: column;
    gap: 40px;

    @media screen and (max-width: 1023px) {
        width: 100%;
        min-width: 100%;
    }
}

.right {
    width: 100%;
}

.acceptText {
    font-family: "Inter";
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0em;
    color: black;
    cursor: pointer;

    // @media screen and (max-width: 480px) {
    // }
}

.home_link {
}

.burger {
    // position: fixed;
    // z-index: 1000;
    // right: 20px;
}
