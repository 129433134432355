.base {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}

.title {
    font-family: 'Inter';
    font-size: 24px;
    color:#fff;
    font-weight: 900;
}

.icon  {
    color:#fff;
    font-size: 26px;
    opacity: 60%;
}

.content {
    font-family: 'Inter';
    font-size: 16px;
    color: #fff;
    font-weight: 400;
}