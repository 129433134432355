@import "../../../core/consts/styleConsts.scss";

.container {
  background: #ffffff;
  box-shadow: 0px 4px 64px 0px #6ad5cb3d;
  border-radius: 24px;
  // width: 600px;
  width: 100%;
  padding: 40px 40px 80px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  background-image: url("../../../core/consts/images/logoGreenXS.png"),
    url("../../../core/consts/images/logoGreen2XS.png");
  background-size: auto;
  background-position: right top, left bottom;
  background-repeat: no-repeat;
  min-width: 330px;
  @media screen and (max-width: 760px) {
    padding: 40px 16px 80px;
  }
  @media screen and (max-width: 480px) {
    background-image: none;
    border-radius: 0;
    box-shadow: none;
  }
}

.link_back {
  color: $ACCEPT;
  text-decoration: none;
  width: fit-content;
}

.tabs {
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  text-transform: none;
}

.button_flex {
  display: flex;
  width: 100%;
  padding: 24px 0;
  gap: 16px;
  justify-content: flex-start;
}

.button_leads_act {
  width: 25%;
  border: none;
  @media screen and (max-width: 599px) {
    width: 50%;
  }
}

.data {
  @include MAIN_TXT($BORDER);
  // margin-top: -16px;
}

.buttonCollapse {
  cursor: pointer;
  color: $ACCEPT;
  text-decoration: none;
  width: fit-content;
}

.dropdownModal {
  width: 100%;
  color: black;
  margin: 16px 0;
  border-radius: 12px;
}

.snackBar {
  background-color: #99d600;
  display: flex;
  justify-content: space-between;
  border-radius: 24px;
  padding: 40px;
  margin: 30px auto 0;
  max-width: 520px;
  min-width: 330px;
  @media screen and (max-width: 480px) {
    margin: -24px 0 auto -16px;
    min-width: 100%;
    max-width: 100%;
  }
}

.currentStatus__container {
  display: flex;
  gap: 8px;
  align-items: center;
}
