.cookiesBox {
    position: fixed;
    width: 360px;
    border-radius: 24px 24px 0px 0px;
    background-color: #ffffff;
    z-index: 1600;
    right: 24px;
    bottom: 0px;
    padding: 24px;
    box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    gap: 16px;

    @media screen and (max-width: 1020px) {
        width: 100vw;
        left: 0;
        bottom: 0;
        padding: 20px;
    }
}

.text {
    font-family: "Inter";
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: 0em;
    color: #636363;
}

.link {
    text-decoration: none;
    text-align: end;
    &:hover {
        color: #617e8c;
    }
    @media screen and (max-width: 1020px) {
        width: 100vw;
        left: 0;
        bottom: 0;
        padding: 20px;
    }
}

.textLink {
    cursor: pointer;
    color: #6ad5cb;
}

.button {
    width: 100%;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 7px;
    color: rgba(255, 255, 255, 1);
    .text {
        font-family: "Inter";
        line-height: 20px;
        font-size: 16px;
        font-weight: 600;
        color: rgba(255, 255, 255, 1);
    }
}
