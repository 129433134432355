.base {
    width: 100%;
    height: 100%;
    background-color: #6ad5cb;
    margin: 0 auto;
    background-image: url("../../../images/logoFirstPage.png");
    background-size: auto;
    background-position: center -20%;
    background-repeat: no-repeat;

    @media screen and (max-width: 1023px) {
        background-size: 402px 407px;
        background-position: 40% -100px;
    }
}

.title {
    font-family: "Inter";
    font-weight: 900;
    font-size: 48px;

    @media screen and (max-width: 1023px) {
        font-size: 32px;
    }
}

.text {
    font-size: 16px;
    font-family: "Inter";
    font-weight: 400;
}

.cardTitle {
    font-family: "Inter";
    font-weight: 900;
    font-size: 16px;
    color: #242424;
    line-height: 19px;
    width: 100%;

    @media screen and (max-width: 1023px) {
        width: 100%;
    }
}

.whiteText {
    color: #ffffff;
}

.acceptText {
    word-wrap: break-word;
    color: #6ad5cb;
}

.containerBlock {
    width: 960px;
    margin: 0 auto;

    @media screen and (max-width: 1022px) {
        width: 100%;
        padding: 0 16px;
    }
}

.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 80px;
}
.enter {
    font-family: "Inter";
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    cursor: pointer;
    padding: 10px 16px;
    border-radius: 24px;
    border: 1px solid #242424;
    &:hover {
        scale: 1.05;
    }
    transition: all 0.5s;
}

.loginBtn {
    font-size: 16px;
    font-family: "Inter";
    cursor: pointer;

    &:hover {
        scale: 1.05;
    }
    transition: all 0.5s;
}

.main {
    display: flex;
    flex-direction: column;
    gap: 56px;
    justify-content: flex-start;
    margin-top: 80px;
}

.mainTitle {
    word-wrap: break-word;
    width: 960px;
    font-family: "Montserrat";
    font-size: 80px;
    font-weight: 900;

    @media screen and (max-width: 1023px) {
        width: 724px;
        font-size: 32px;
    }

    @media screen and (max-width: 1022px) {
        width: 100%;
        font-size: 32px;
    }
}

.mainButton {
    display: flex;
    align-items: center;
    gap: 7px;
    padding: 22px 50px;
    color: rgba(255, 255, 255, 1);
}

.mainButtonText {
    font-family: "Inter";
    line-height: 20px;
    font-size: 16px;
    font-weight: 600;
}

.whyWe {
    display: flex;
    flex-direction: column;
    gap: 32px;
    margin-top: 88px;
}

.whyWeCard {
    width: 222px;
    height: 156px;

    @media screen and (max-width: 1023px) {
        width: 100%;
        height: 85px;
    }

    @media screen and (max-width: 1022px) {
        width: 100%;
        height: fit-content;
    }
}

.cards {
    display: flex;
    gap: 24px;

    @media screen and (max-width: 1023px) {
        flex-direction: column;
        gap: 8px;
    }
}

.aboutAccount {
    display: flex;
    gap: 11px;
    align-items: center;
    margin-top: 120px;

    @media screen and (max-width: 1022px) {
        display: flex;
        flex-direction: column;
        gap: 32px;
    }
}

.aboutAccountTitle {
    width: 432px;

    @media screen and (max-width: 1023px) {
        width: 404px;
    }

    @media screen and (max-width: 1022px) {
        width: 100%;
    }
}

.aboutAccountContent {
    display: flex;
    flex-direction: column;
    gap: 24px;
    justify-content: flex-start;
}

.accountScreen {
    width: 460px;
    height: 374px;

    @media screen and (max-width: 1022px) {
        width: 100%;
        height: 100%;
    }
}

.aboutSupportWrapper {
    background-color: rgba(20, 63, 72, 1);
    padding: 80px 0;
    background-image: url("../../../images/darkGreenLogo.png");
    background-size: 625px 631px;
    background-position: 37% 60%;
    background-repeat: no-repeat;

    @media screen and (max-width: 1022px) {
        background-size: 120%;
        background-position: 47% 0px;
    }
}

.aboutSupport {
    display: flex;
    gap: 50px;

    @media screen and (max-width: 1022px) {
        flex-direction: column;
    }
}

.massagesImg {
  width: 510px;
  height: 100%;
    @media screen and (max-width: 1022px) {
        width: 100%;
        height: 100%;
    }
}

.aboutSupportContent {
    display: flex;
    flex-direction: column;
    gap: 32px;
}

.aboutSupportTitle {
    width: 432px;

    @media screen and (max-width: 1023px) {
        width: 100%;
        font-size: 48px;
    }
}

.aboutSupportText {
    width: 368px;
    color: rgba(255, 255, 255, 1);
    line-height: 26px;

    @media screen and (max-width: 1022px) {
        width: 100%;
        height: 100%;
    }
}

.aboutUs {
    margin-top: 120px;
    display: flex;
    flex-direction: column;
    gap: 40px;
}

.aboutUsTitle {
    width: 960px;

    @media screen and (max-width: 1023px) {
        width: 100%;
    }

    @media screen and (max-width: 1023px) {
        font-size: 32px;
    }
}

.aboutUsCardTitle {
    font-family: "Inter";
    font-size: 48px;
    font-weight: 900;
    line-height: 52px;
}

.aboutUsCards {
    @media screen and (max-width: 1023px) {
        display: flex;
        flex-direction: row;
        gap: 8px;
    }

    @media screen and (max-width: 1022px) {
        flex-direction: column;
    }
}

.aboutUsCard {
    width: 224px;
    height: 136px;
    gap: 0;
    padding: 24px;

    @media screen and (max-width: 1023px) {
        width: 100%;
        height: 100px;
        flex-direction: row;
        align-items: center;
        gap: 12px;
    }
}

.aboutUsCardText {
    line-height: 19px;
}

.firstCard {
    width: 480px;
    height: 136px;
    background-color: #ffffff;
    border-radius: 16px;
    display: flex;
    align-items: center;
    padding: 24px;
    gap: 24px;

    @media screen and (max-width: 1023px) {
        width: 460px;
        height: 207px;
        padding: 10px 24px;
        flex-direction: column;
        align-items: flex-start;
        gap: 0;
    }

    @media screen and (max-width: 1022px) {
        width: 100%;
        height: fit-content;
    }

    .count {
        font-family: "Montserrat";
        font-weight: 900;
        font-size: 96px;
    }

    .firstCardTitle {
        font-family: "Inter";
        font-weight: 900;
        font-size: 24px;
    }

    .firstCardText {
        color: #636363;
        font-family: "Inter";
        font-weight: 400;
        font-size: 16px;
    }
}

.firstCardContent {
    display: flex;
    flex-direction: column;
    gap: 2px;
}

.order {
    margin-top: 16px;

    @media screen and (max-width: 1022px) {
        margin-top: 56px;
        padding: 0;
    }
}

.questionsWrapper {
    margin-top: 120px;
    padding: 120px 0 80px;
    background-color: rgba(20, 63, 72, 1);

    @media screen and (max-width: 1022px) {
        margin-top: 0;
        padding: 56px 0 80px;
    }
}

.questions {
    display: flex;
    flex-direction: column;
    gap: 40px;
}

.questionsTitle {
    word-wrap: break-word;
    color: #ffffff;

    @media screen and (max-width: 1022px) {
        font-size: 48px;
    }
}

.accordions,
.accordion {
    display: flex;
    flex-direction: column;
    gap: 32px;
}

.separator {
    width: 100%;
    height: 1px;
    background-color: rgba(255, 255, 255, 1);
    opacity: 20%;
}
