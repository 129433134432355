.base {
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 16px;
    background-color: rgba(255, 255, 255, 1);
}

.title {
    width: 100%;
}

.text {
    font-family: "Inter";
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    color: rgba(99, 99, 99, 1);
}