.base {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.text {
    font-family: "Inter";
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0em;
    color: #636363;
}

.button {
    width: 100%;

    .content {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 7px;
        padding: 22px 50px;
        color: rgba(255, 255, 255, 1);
        
        .text {
            font-family: "Inter";
            line-height: 20px;
            font-size: 16px;
            font-weight: 600;
            color: rgba(255, 255, 255, 1);
        }
    }
}
