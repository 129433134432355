@import "../../core/consts/styleConsts.scss";
.dialog {
  // width: 600px;
  width: 100%;
  max-width: 100%;
  @media screen and (max-width: 480px) {
    min-height: 100vh;
  }
}
.modal_cont {
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 480px) {
    min-height: 100vh;
    background-color: $ACCEPT;
  }
}
.paper {
  max-width: 600px;
  display: flex;
  // width: 600px;
  padding: 40px;
  flex-direction: column;
  align-items: start;
  gap: 24px;
  border-radius: 24px;
  background: var(--background, #fff);
  box-shadow: 0px 4px 64px 0px rgba(106, 213, 203, 0.24);
  background-image: url("../../core/consts/images/logoGreenXS.png");
  background-size: auto;
  background-position: right top;
  background-repeat: no-repeat;
  min-width: 300px;

  @media screen and (max-width: 760px) {
    padding: 40px 16px;
  }
  @media screen and (max-width: 480px) {
    background-image: none;
    border-radius: 0;
  }
}
.spacer {
  @media screen and (max-width: 480px) {
    flex-grow: 1;
  }
}
.main_title {
  @media screen and (max-width: 780px) {
    @include H2($MAIN_TEXT);
  }
}
.formBox {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.error_flex {
  display: flex;
  gap: 8px;
  align-items: center;
  color: $ERROR;
  margin-top: -12px;
}
.confirm_desc {
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;

  @media screen and (max-width: 700px) {
    width: 100%;
  }
  .blue_text {
    color: $ACCEPT;
    cursor: pointer;
    font-weight: 400;
  }
}

.titleBox {
  display: flex;
  justify-content: space-between;
  align-items: baseline;

  @media screen and (max-width: 480px) {
    flex-wrap: wrap;
    flex-direction: column;
  }
}
.header {
  align-self: flex-start;
  display: flex;
  flex-direction: column;
  gap: 24px;
}
%typeItem {
  border-radius: 24px;
  border: 1px solid var(--border, #ccc);
  background: var(--background, #fff);
  width: 100%;
  margin-bottom: 15px;
  padding: 32px;
  max-height: 200px;
  min-height: 135px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 700px) {
    width: 100%;
  }
}
.typeItem {
  @extend %typeItem;
}
.typeItem_cert {
  @extend %typeItem;
  height: 220px;
  @media screen and (max-width: 480px) {
    display: none;
  }
}
.typeItem_title {
  @include H2($MAIN_TEXT);
}
.typeItem_desc {
  margin-top: 16px;
  @include MAIN_TXT($SUB_TEXT);
}

.codeContainer {
  display: flex;
  width: 100%;
  max-width: 240px;
  gap: 8px;
}
.inputCode {
  width: 40px;
  max-width: 40px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

// **************************

.arrow {
  //   width: 20px;
  //   height: 20px;
}
%link_back {
  display: flex;
  align-items: center;
  height: 20px;
  gap: 10px;

  margin-bottom: 20px;
  cursor: pointer;
  justify-self: flex-start;
}
.link_back {
  @extend %link_back;
}
.link_back_role {
  @extend %link_back;
  margin-bottom: 0px;
  max-height: 20px;
}
.link_back_desctop {
  @extend %link_back;
  @media screen and (max-width: 480px) {
    display: none;
  }
}

.img_fit {
  object-fit: contain;
}

.container {
  width: 70%;
  font-size: 16px;
  line-height: 130%;
  color: #131313;
  @media screen and (max-width: 700px) {
    width: 100%;
  }
}
%role_box {
  border: 1px solid rgb(188, 186, 186);
  border-radius: 5px;
  padding: 25px;
  font-family: "Montserrat";
  font-style: normal;
  color: #000000;
  width: 80%;
  margin-top: 15px !important;
  display: flex;
  gap: 15px;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 0;
  height: 100% !important;
  @media screen and (max-width: 700px) {
    width: 100%;
  }
}
.role_box {
  @extend %role_box;
}
.role_box_active {
  @extend %role_box;
  border: 1px solid #306f9a;
}
.flex {
  display: flex;
  gap: 15px;
}
.role_box_title {
  font-weight: 600 !important;
  font-size: 24px !important;
  line-height: 29px;
  color: #000000;
}
.role_box_desc {
  font-weight: 400;
  font-size: 16px;
  line-height: 130%;
  color: #131313;
  margin-bottom: 15px;
}
.button_reg {
  width: 80%;
  margin-top: 20px !important;
  @media screen and (max-width: 700px) {
    width: 100%;
  }
}
.error {
  color: red;
  margin-top: 15px;
}
.flex_up {
  display: flex;
  align-items: flex-start;
  gap: 15px;
}
.flex_end {
  display: flex;
  justify-content: space-between;
  width: 100%;
  // @media screen and (min-width: 480px) {
  //   display: none;
  // }
}
.note_mes {
  margin-top: 24px;
  text-align: center;
}

// *******************************MUI**************************

.dialog {
  max-width: 100%;
  @media screen and (max-width: 780px) {
    width: 100%;
    // &:nth-child(3n) {
    //   max-width: 100%;
    //   & > div {
    //     width: 100%;
    //     max-width: 100%;
    //     padding: 0;
    //     margin: 0;
    //     height: 100vh;
    //   }
    // }
  }
}
// .paper {
//   display: block;
//   width: 780px;
//   // background-color: theme.palette.background.paper,
//   border-radius: 10px;
//   height: fit-content;
//   padding: 50px 75px;
//   @media screen and (max-width: 780px) {
//     border-radius: 0;
//     max-width: 100%;
//   }
//   @media screen and (max-width: 480px) {
//     padding: 35px 16px;
//   }
// }
.login {
  max-width: 100%;
  // backgroundColor: theme.palette.background.paper,
  border: none;
}

.title {
  margin-bottom: 25px;
  @media screen and (max-width: 480px) {
    margin-bottom: 16px;
  }
}
.subtitle {
  margin-bottom: 25px;
  max-width: 500px;
  @media screen and (max-width: 480px) {
    margin-bottom: 16px;
  }
}
.linkFormChange {
  margin: 3% 0;
  cursor: pointer;
}
.link {
  text-decoration: none;
  // color: theme.palette.primary.main,
}
.arrowBackBtn {
  display: flex;
  // justify-content: space-start,
  padding: 0;
  margin-bottom: 25px;
  svg {
    fill: #000000;
    font-size: 22px;
  }
  @media screen and (max-width: 480px) {
    display: none;
  }
}
.closeBtn {
  svg: {
    fill: #000000;
    font-size: 22px;
  }
  @media screen and (max-width: 480px) {
    display: none;
  }
}
.closeBtnMobile {
  margin-bottom: 15px;
  svg: {
    fill: #000000;
    font-size: 22px;
  }
  @media screen and (max-width: 480px) {
    display: none;
  }
}

.blockMobButton {
  padding: 18px 36px;
  margin-top: 40px;
  @media screen and (max-width: 480px) {
    width: 100%;
    margin-top: calc(100vh - 325px);
  }
}
