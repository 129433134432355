.base {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.content {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.subTitle {
    font-family: "Inter";
    font-size: 16px !important;
    font-weight: 900 !important;
    line-height: 19px !important;
    letter-spacing: 0em !important;
    color: #636363 !important;
}

.text {
    font-family: "Inter";
    font-size: 16px !important;
    font-weight: 400 !important;
    line-height: 26px !important;

    color: #636363 !important;
}
