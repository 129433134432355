@import "../../../core/consts/styleConsts.scss";

%paper_block {
  width: 100%;
  background: #ffffff;
  border-radius: 10px;
  // padding: 40px 30px 30px 30px;
  @include MAIN_TXT($MAIN_TEXT);
}

.note_card {
  border-radius: 16px;
  border: 1px solid var(--border, #ccc);
  background: var(--background, #fff);
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 32px 24px 24px 24px;
}

.note_card_time {
  @include SMALL_TXT($BORDER);
}

.note_card_text {
  @include MAIN_TXT($SUB_TEXT);
}

.note_card_button_area {
  width: 100%;
  display: flex;
  // justify-content: space-between;
}
.butB {
  padding: 16px 24px;
  @media screen and (max-width: 480px) {
    padding: 16px 8px;
  }
}

.note_card_create {
  @include SMBOLD_TXT($MAIN_TEXT);
}

%flex_gap12 {
  display: flex;
  gap: 12px;
  align-items: center;
}

.flex_gap12 {
  @extend %flex_gap12;
  opacity: 1 !important;
}

.flex_gap20 {
  display: flex;
  gap: 20px;
}

.note_view {
  @extend %paper_block;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.note_view_text {
  width: 100%;
  min-height: 80px;
  padding: 10px 14px;
  max-width: 100%;
  min-width: 100%;
  // resize: none;
  @include MAIN_TXT($SUB_TEXT);
}

.relative {
  position: relative;
}

.symbols_amount {
  position: absolute;
  right: 10px;
  bottom: 10px;
  color: #b0bfc6;
}
.div100 {
  width: 100%;
}
.no_grow {
  flex-grow: 0;
  @media screen and (max-width: 1200px) {
    flex-grow: 1;
  }
}
.main_column {
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 24px;
}

%notification_settings {
  display: flex;
  align-items: center;
  gap: 16px;
  flex-grow: 1;
}

.notification_settings_common {
  @extend %notification_settings;
}

.notification_settings_active {
  @extend %notification_settings;
}

.notification_settings_passive {
  @extend %notification_settings;
  opacity: 0.2;
}

.is_notificate_checkbox {
  width: 24px;
  height: 24px;
  @media screen and (max-width: 760px) {
    width: 20px;
    height: 20px;
  }
}

.remind {
  font-size: 16px;
}

.field_time {
  min-width: 64px !important;
  width: 64px !important;
  @media screen and (max-width: 760px) {
    min-width: 120px !important;
    width: 100% !important;
  }
}

.field_calendar {
  align-items: flex-end;
  width: 100% !important;
}
.field_calendar_end {
  max-width: 300px;
  @media screen and (max-width: 1200px) {
    max-width: none;
    width: 100%;
  }
}

.field_repeat_type {
  // max-width: 700px;
}

%button {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 50%;
}

.button_submit {
  @extend %button;
}

.button_cancel {
  @extend %button;
}

.button_create_note {
  @include MAIN_TXT($SUB_TEXT);
  border: 1px solid #b0bfc6;
  border-radius: 12px;
  height: 64px;
  width: 100%;
  padding: 0 12px;
  // margin-bottom: 24px;
  // margin-top: 24px;
  cursor: pointer;
  background-color: $BACKGROUND;

  display: flex;
  align-items: center;
  justify-content: space-between;
}

%status {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 6px;
  height: 18px;
  border-radius: 24px;
  // font-family: "Gotham Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 130%;
}

.status_overdue {
  @extend %status;
  background: $ERROR;
  color: $BACKGROUND;
}

.status_today {
  @extend %status;
  background: $ATTENTION;
  color: $MAIN_TEXT;
}
.mobile_column {
  display: flex;
  gap: 24px;
  @media screen and (max-width: 760px) {
    flex-direction: column;
    // justify-content: flex-start;
    align-items: start;
    width: 100%;
    gap: 24px;
  }
}
.days {
  gap: 24px;
  @media screen and (max-width: 1200px) {
    flex-wrap: wrap;
  }
}
