@import "../../../core/consts/styleConsts.scss";

.base {
    width: fit-content;
    height: fit-content;
    background-color: rgba(36, 36, 36, 1);
    border-radius: 12px;
    cursor: pointer;
    border: none;
    outline: none;
    transition: all 0.5s;

    &:hover {
        background: $SUB_TEXT;
    }
}