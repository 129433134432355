.base {
    width: 960px;
    height: fit-content;
    padding: 48px 40px;
    background-color: rgba(255, 255, 255, 1);
    border-radius: 24px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    box-sizing: border-box;

    @media screen and (max-width: 1022px) {
        width: 100%;
        border-radius: 0;
        padding: 56px 16px;
    }
}

.acceptText {
    cursor: pointer;
    color: #6ad5cb;
}

.header {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.title {
    font-family: "Inter";
    font-weight: 900;
    line-height: 52px;
    font-size: 48px;
    width: 853px;

    @media screen and (max-width: 1022px) {
        width: 100%;
        font-size: 32px;
        line-height: 39px;
    }
}

.text {
    font-size: 16px;
    font-family: "Inter";
    font-weight: 400;
}

.form {
    display: flex;
    gap: 16px;

    @media screen and (max-width: 1022px) {
        flex-direction: column;
    }
}

.control {
    display: flex;
    gap: 16px;
    align-items: center;

    @media screen and (max-width: 1022px) {
        flex-direction: column;
        gap: 24px;
    }
}

.input {
    width: 456px !important;
    height: 64px;

    @media screen and (max-width: 1022px) {
        width: 100% !important;
    }
}

.button {
    font-family: 'Inter';
    font-weight: 600;
    font-size: 16px;
    padding: 22px 32px;
    color: rgba(255, 255, 255, 1);

    @media screen and (max-width: 1022px) {
        width: 100%;
    }
}

.checkboxWrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;

    @media screen and (max-width: 1022px) {
        align-items: flex-start;
    }
}

.info {
    font-family: "Inter";
    font-size: 16px;
    font-weight: 400;
    color: rgba(99, 99, 99, 1);
    font-family: Inter;
    line-height: 26px;
    letter-spacing: 0em;
}