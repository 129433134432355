@import "../../core/consts/styleConsts.scss";
.layout_main {
    width: 100%;
    min-height: 100vh;
    background: $ACCEPT;

    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 1;
    overflow: hidden;
}
.layout_main:before {
    z-index: -1;
    position: absolute;
    right: 7%;
    top: 200px;
    content: url("../../core/consts/images/logoWhite.png");
    opacity: 0.4;
}
.container {
    width: 100%;
    display: flex;
    gap: 10px;
    height: 94px;
    background: #ffffff;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
    padding-left: 8%;
    padding-right: 8%;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    line-height: 17px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    position: sticky;
    top: 0px;
    z-index: 100;
    @media screen and (max-width: 700px) {
        padding: 10px 20px 10px 20px;
    }
}

// .footer {
//     padding: 25px 16%;
//     display: flex;
//     flex-direction: column;
//     gap: 20px;
//     justify-self: flex-end;
//     color: $BACKGROUND;

//     @media screen and (max-width: 760px) {
//         padding: 10px 20px;
//     }
// }

.containerBlock {
    width: 960px;
    margin: 0 auto;

    @media screen and (max-width: 1022px) {
        width: 100%;
        padding: 0 16px;
    }
}

.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 80px;
}

.footerWrapper {
    background-color: rgba(10, 32, 36, 1);
    padding: 80px 0;

    @media screen and (max-width: 1022px) {
        padding: 40px 0;
    }
}

.footer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 8px;

    .text {
        color: rgba(255, 255, 255, 1);
        font-family: "Inter";
        font-size: 16px;
        font-weight: 400;
        line-height: 19px;
    }
}

.logo {
    width: 126px;
    height: 35px;
}

.textWrapper {
    display: flex;
    gap: 80px;

    @media screen and (max-width: 1023px) {
        flex-direction: column;
        gap: 40px;
    }
}

.left {
    width: 390px;
    min-width: 390px;
    display: flex;
    flex-direction: column;
    gap: 40px;

    @media screen and (max-width: 1023px) {
        width: 100%;
        min-width: 100%;
    }
}

.right {
    width: 100%;
}

// .header {
//   padding: 25px 16%;
//   display: flex;
//   gap: 20px;
//   justify-content: space-between;
//   align-items: center;
//   color: $MAIN_TEXT;
//   @include MAIN_TXT($MAIN_TEXT);
//   @media screen and (max-width: 760px) {
//     padding: 10px 20px;
//   }
// }
.enter {
    font-family: "Inter";
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    cursor: pointer;
    padding: 10px 16px;
    border-radius: 24px;
    border: 1px solid #242424;
    &:hover {
        scale: 1.05;
    }
    transition: all 0.5s;
}

.acceptText {
    font-family: "Inter";
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0em;
    color: #6ad5cb;
    cursor: pointer;
}
